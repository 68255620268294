import React, { Component } from 'react';
import { DatePicker} from 'antd';
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
    let {publicState } = state;
    return {publicState };
};

class TimeCompoent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            endOpen: false
        };
        this.curMouth = {
            start: '',
            end: ''
        };
        this.days = [];
        this.timer = null;
    }
    disabledStartDate = startValue => {
        const { endValue } = this.props;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf() || (startValue.year() < 2000 || startValue.year() > 2037);
    };

    disabledEndDate = endValue => {
        const { startValue } = this.props;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf() || (endValue.year() < 2000 || endValue.year() > 2037);
    };

    onChange = (field, value) => {
        let { changeTime } = this.props;
        changeTime(field, value);
    };

    onStartChange = value => {
        let { changeTime } = this.props;
        changeTime('startTime', value);
    };

    onEndChange = value => {
        let { changeTime } = this.props;
        changeTime('endTime', value);
    };

    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = open => {
        this.setState({ endOpen: open });
    };

    custumRenderDate = (mouth, classname) => {
        this.days.push(mouth);
        // antd 不支持异步渲染，所以采用先渲染，后标记方法，固定42个日期，之后做处理
        // console.log(this.days);

        if (this.days.length === 42) {
            this.requestMarkDay(this.days, classname);
            this.days = [];
        }
        return (
            <div className="ant-calendar-date">
                {mouth.format('YYYY-MM-DD').split('-')[2]}
            </div>
        );
    }

    requestMarkDay (days, status) {
        // 找当前月份, 由于antd会自动补全星期，所以如果第一天是1号，则第一天就是当前月份，否则下月才是当前月份
        let firstDay = days[0].clone();
        let seletMouth;
        if (firstDay.get('date') === 1) {
            seletMouth = firstDay.format('YYYY-MM');
        } else {
            seletMouth = firstDay.clone().add(1, 'months').format('YYYY-MM');
        }

        // 记录当前月份，对比异步请求，防止误渲染
        this.curMouth[status] = seletMouth;

        let year = seletMouth.split('-')[0] - 0;
        let mouth = seletMouth.split('-')[1] - 0;

        // 获取前补位日期个数
        let preMouths;
        if (firstDay.get('date') === 1) {
            preMouths = [];
        } else {
            preMouths = days.filter(day => days[0].isSame(day, 'month'));
        }
        this.clearMark();

        // 防抖
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.getBitByChannel(this.props.channel, year, mouth, preMouths.length, status);
        }, 500);

    }

    getBitByChannel (channel, year, mouth, preLength, status) {
        let param = {
            condition: {
                Channel:channel,
                Types:['jpg'],
                Events:'*',
                Year:year,
                Month:mouth
            }
        };
        this.props.getBitMap({name:'/mnt/sd'}, param, status).then(({bitmap, status, time}) => {
            //请求是异步,防止延迟，所以对比请求回来的月份是否跟当前月份一样
            if (this.curMouth[status] !== time) return;
            if (bitmap > 0) {
                var bit = bitmap.toString(2),
                    len = bit.length;
                while (--len) {
                    // 根据前后端制定规则，满足下面条件则len就是有录像的日期
                    if (bit.charAt(bit.length - len - 1) === '1') {
                        // 加上补位个数
                        let aftLen = len + preLength;
                        this.reRenderMarkDay(aftLen);
                    }
                }
            }
        });
    }
    reRenderMarkDay (aftLen) {
        // 日历外层dom只有一个，找到对应日期的dom，并加上class
        let tbody = document.querySelector('.ant-calendar-tbody');
        if (!tbody) return;

        let td = tbody.querySelectorAll('td')[aftLen - 1];
        let classname = td.getAttribute('class');
        if (!classname.includes('ant-calendar-mark')) {
            classname = td.getAttribute('class') + ' ant-calendar-mark';
        }
        td.setAttribute('class', classname);
    }
    clearMark () {
        // 渲染前先清除之前的标记
        let tbody = document.querySelector('.ant-calendar-tbody');
        if (!tbody) return;

        let tds = tbody.querySelectorAll('td');
        let i = tds.length - 1;
        while (i > -1) {
            let td = tds[i];
            let classname = td.getAttribute('class');
            classname = classname.replace(' ant-calendar-mark', '');
            td.setAttribute('class', classname);
            i--;
        }
    }

    render () {
        const {
            startValue,
            endValue,
            publicState:{
                timeFormat
            }
        } = this.props;

        return (
            <div>
                <DatePicker
                    className='actions startTime fn-margin-bottom10'
                    disabledDate={this.disabledStartDate}
                    showTime
                    format={timeFormat}
                    value={startValue}
                    onChange={this.onStartChange}
                    dateRender = {(mouth) => this.custumRenderDate(mouth, 'start')}
                    style={{ minWidth: 190 }}
                    allowClear = {false}
                />
                <DatePicker
                    className='actions fn-margin-bottom10'
                    disabledDate={this.disabledEndDate}
                    showTime
                    format={timeFormat}
                    value={endValue}
                    onChange={this.onEndChange}
                    dateRender = {(mouth) => this.custumRenderDate(mouth, 'end')}
                    style={{ minWidth: 190 }}
                    allowClear = {false}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps,undefined)(TimeCompoent);